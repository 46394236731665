import React from 'react';
import { useSiteData, DataContextProps } from '@context';
import { EmpowerMeLogo } from '@components';
import FooterNavigation from './footer-navigation';

/**
 * Footer
 */
const Footer = (): JSX.Element | null => {
  const { siteData }: DataContextProps = useSiteData();
  if (siteData) {
    const { translations, currentLocale } = siteData;

    /**
     * Render.
     */
    if (translations) {
      return (
        <footer>
          <div className="footer-container">
            <div className="safety">
              <EmpowerMeLogo />
              <FooterNavigation />
              <h2>{translations.safety}</h2>
              {currentLocale === 'en' ? (
                <p>
                  Important Safety Notice: Varian products used to treat cancer
                  and other conditions pose the risk of side effects, which can
                  vary based on the treatment modality and part of the body
                  being treated. Safety information regarding risks associated
                  with the use of our products can be found on our{' '}
                  <a
                    href="https://www.varian.com/about-varian/legal-information/intended-use-summary-important-safety-information"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Safety Information page.
                  </a>
                </p>
              ) : (
                <p>{translations.safety_description}</p>
              )}
              <span className="copy">
                © 1999 - {new Date().getFullYear()} Varian Medical Systems,
                Inc. All Rights Reserved.
              </span>
              <div id="consent_blackbar" />
              <div id="teconsent" />
            </div>
            <FooterNavigation />
          </div>
        </footer>
      );
    }
  }
  return null;
};
export default Footer;
